const ot = {
  name: 'ot-branded',
  font: {
    'font-family': 'Geist',
    'generic-family': 'sans-serif',
    'fnt-file-name': 'geist_medium_12.fnt',
  },
};

export const themes = {
  [ot.name]: ot,
  default: ot,
};
